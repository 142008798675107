<template>
    <div>
        <!-- TODO: we need an error modal. waiting on design/business
			<DialogModal
            ariaLabel="Payment page issue."
            :showCloseButton="false"
            :isOpen="hasConsumerLeadError"
            :disableEscape="true"
        >
            <h4 slot="header">Consumer Lead Error</h4>
            <p>Oh dang!</p>
            <button slot="footer">Reload Page</button>
        </DialogModal> -->

        <Checkout
            :hasCreditCardCheckout="hasCCCheckout"
            :query="query"
            @taxesDone="trackCheckoutStarted"
        />
    </div>
</template>

<script>
import Checkout from '@/components/Checkout.vue';
import { isEmpty } from 'underscore';
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import { RudderstackCheckoutMixin } from '@/rudderstack/track/mixins/checkout';
import { RudderstackChatMixin } from '@/rudderstack/track/mixins/chat';
import DialogModal from '@/components/DialogModal.vue';

export default {
	components: {
		Checkout,
		DialogModal
	},

	mixins: [RudderstackCheckoutMixin, RudderstackChatMixin],

	props: {
		query: {
			required: true,
			type: Object
		}
	},

	data() {
		return {
			hasConsumerLeadError: false
		};
	},

	watch: {
		'$store.state.consumerInfo.userLanguage': function() {
			// true fed to fetchContent to trigger re-fetching of content if userLanguage is changed
			this.fetchContent(true);
		}
	},

	computed: {
		...mapGetters('cart/order', ['isMobileInstallation']),
		...mapState('dealer', {	selectedDealer: 'selected' }),
		...mapState('cart', ['tiresInCart']),
		...mapState('cart/order', ['details']),
		...mapState('consumerInfo', ['userLanguage']),
		...mapState('initial', ['title']),
		...mapState('search', ['dealership_id']),

		hasCCCheckout() {
			return this.$store.state.initial.hasCreditCardCheckout;
		},
		newPageTitle() {
			// eslint-disable-next-line prefer-template
			const page = this.$t('message.checkoutPage') + ' | ' + this.title;

			return page;
		}
	},

	methods: {
		...mapActions('dealer', { selectDealer: 'select' }),
		...mapActions('cart', ['fetchCart']),
		...mapActions('products', ['getInventory']),
		...mapActions('appointment', ['getMobileInstallZip']),
		...mapActions('serviceOfferings', ['addMobileInstallFee']),
		...mapActions('content/payment', ['fetchContent']),
		...mapMutations('products', ['checkStaggered']),
		...mapMutations('cart/order', ['setOrder']),
		...mapActions('cart/order', ['consumerLeadInitial']),
		async handlePageLoadWithNoDealerSelected() {
			const qs = this.query;

			// This block will execute on a page refresh
			if (isEmpty(this.selectedDealer)) {
				// Action - Sets store.dealer.selected
				await this.selectDealer({
					uid: qs.uid,
					make: qs.make
				});
				// Mutation - Sets store.products.isStaggered
				await this.checkStaggered(qs.sku);
				// Get cart from session
				await this.fetchCart(qs);
				await this.getInventory({ products: this.tiresInCart.products,
					qty: !Array.isArray(qs.qty) ? qs.qty.split(',') : qs.qty });

				// Action - Sets store.schedule.appointment
				if (this.isMobileInstallation) {
					// Action - Sets mobileInstallInfo.zip in cart/order/details
					this.getMobileInstallZip();
					this.addMobileInstallFee();
				}
			}
		},
		async getInitialConsumerLeadID() {
			// Generate consumerLeadID with order details (this.details)
			try {
				let leadInitialError = await this.consumerLeadInitial(this.details);

				if (leadInitialError) {
					this.hasConsumerLeadError = true;
					console.error('Error initializing consumer lead: ', leadInitialError);
				}
			} catch (err) {
				this.hasConsumerLeadError = true;
				console.error('Error fetching consumer lead: ', err);
			}
		},
		setOrderDetails() {
			const qs = this.query;

			// Set order details
			this.setOrder({
				uid: qs.uid,
				vehicle: {
					year: qs.year,
					make: qs.make,
					model: qs.model,
					trim: qs.trim,
					option: qs.option,
					containsStaggeredSet: qs.sku.split(',').length > 1
				},
				leadSearchType: qs.isbysize ? 'searchByTireSize' : 'searchByVehicle'
			});
		},
		setupPaymentPage() {
			// 1. no dealer selected (on page refresh)
			this.handlePageLoadWithNoDealerSelected();

			// 2. set order details
			this.setOrderDetails();

			// 3. get consumerLeadID
			this.getInitialConsumerLeadID();
		}
	},

	mounted() {
		document.title = this.newPageTitle;
	},

	created() { // eslint-disable-line complexity
		if (sessionStorage.getItem('orderNo') !== '') {
			sessionStorage.removeItem('orderNo');
		}

		// Async - Get CMS Content
		this.fetchContent();

		this.setupPaymentPage();

		this.$rudderstack.page(this.$route.meta.pageCategory, this.$route.meta.pageCategory, {
			...this.$route.query,
			'dealership_id': this.dealership_id
		});
	},
	// If the browser doesn't have the needed query strings boot back to index
	beforeRouteEnter(to, from, next) {
		next( vm => {
			if (!vm.$route.query.make && !vm.$route.query.sku && !vm.$route.query.qty) {
				return vm.$router.push('/');
			}

			return true;
		});
	}
};
</script>
